.navbar-custom {
    background-color: #e7e7e7;
}

.responsive-img {
    width: 100%;
    max-width: 1427px;  
    height: auto;  /* Maintain aspect ratio */
    display: block;
    margin-left: auto;
    margin-right: auto;  
  }


/*For home page*/
.main-container {
    max-width: 1427px; /* 画像の最大幅と同じ */
    margin: 0 auto; /* 中央揃え */
  }
.split-container {
    display: flex;
    justify-content: space-between;
  }
  
  .split-container .text-section {
    flex: 1;
    padding-right: 20px;
  }
  
  .split-container .form-section {
    flex: 1;
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {
    .split-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .text-section {
      margin-bottom: 20px; /* Adjust as needed */
    }
  }
  